import { Link } from 'gatsby';
import React from 'react';

const Deparments = () => {
  return (
    <section className='faq-one'>
      <div className='container'>
        <div className='row no-gutters'>
        <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='/allied-departments/pharmacy'>
                  Pharmacy
                  </Link>
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='/allied-departments/physiotherapy'>Physiotherapy</Link>
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='/allied-departments/nutrition-diatetics'>Nutrition & Dietetics</Link>
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='/departments/child-dental'> */}
                  {/* Audit Department */}
                  {/* </Link> */}
                </h2>
              </div>
            </div>
          </div>          
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='#'>Clinical Services And Training Division</Link> */}
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='#'>Corporate Services</Link> */}
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='/departments/ophthalmology'> */}
                  {/* Corporate Social Investment */}
                  {/* </Link> */}
                </h2>
              </div>
            </div>
          </div> 
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='/non-departments/servicom'>
                  Servicom Unit
                  </Link> */}
                </h2>
              </div>
            </div>
          </div>          
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='#'>Engineering Services</Link> */}
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='#'>Finance And Accounts</Link> */}
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='#'> */}
                  {/* General Administration Division */}
                  {/* </Link> */}
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='/departments/oral-surgery'> */}
                  {/* Hospital Facilities Management */}
                  {/* </Link> */}
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='#'>Human Resources Management Division</Link> */}
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='#'>Legal Services Department</Link> */}
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='/departments/orthopaedics'> */}
                  {/* Printing Unit */}
                  {/* </Link> */}
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='/departments/comm-health'> */}
                  {/* Procurement Department */}
                  {/* </Link> */}
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='/inprogress/departments/ent'> */}
                  Protocol Unit
                  {/* </Link> */}
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='#'>School Administration </Link>
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='/inprogress/departments/family-medicine'> */}
                  Security Unit
                  {/* </Link> */}
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='#'>Staff Development, Training And Welfare</Link>
                </h2>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  {/* <Link to='#'> */}
                  Stores Department
                  {/* </Link> */}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Deparments;
