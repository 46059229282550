import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/Layout';
import NavOne from '../components/NavOne';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import AlliedDeparments from '../components/AlliedDepartments';

const FaqPage = () => {
  // useEffect(() => {
  //   navigate('/inprogress/departments');
  // }, []);
  return (
    <Layout pageTitle='Allied Clinical Departments | LUTH'>
      <NavOne />
      <PageHeader title='Allied Clinical Departments' />
      <AlliedDeparments />
      <Footer />
    </Layout>
  );
};

export default FaqPage;
